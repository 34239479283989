/* common css */
.hdding {
  font-size: 40px;
}
/* common css */
/* section 1 start */
.CantColor {
   background: #e8f4f0;
  height: 500px;
}
.p-150 {
  padding: 10px 15px;
  line-height: 1.5;
}



@media (min-width: 1281px) {
  .p-150 {
    padding: 10px 155px !important;
    line-height: 1.5;
  }

  .py-40 {
    padding: 80px 80px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .CantColor {
    background-color: rgb(232, 243, 247);
    height: 531px;
  }

  .py-40 {
    padding: 0px 0px 28px 0px;
  }
}
/* section 3 start */
.dip{
    width: 300px;
}
.sec3color{
    background-color: rgb(239, 231, 240);
}
/* section  start */