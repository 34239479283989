.homebackground{
    background-color: #e8f4f0;
    height:400px;
}

.page-title{
    font-size:34px !important ;
    color: #222;
    font-weight: bold;
    line-height: 1.2em;
}
.span-cr{
    font-size:34px !important ;
    color:#08AA8D !important;
    font-weight: bold;
    line-height: 1.2em;

}
.picture{
    width: 100%;
}
.page-tile{
    font-size:34px !important ;
    color: #222;
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
    padding: 0px 0px 26px 0px;
}

.para-text{
    font-size: 20px;
    color: #222;
    font-weight: 600;
    text-align: center;
    line-height: 1.2em;
}
.para-text p{
    padding: 0px 10px 0px 10px !important;

}
.listed p{
    font-size: 15px;
    color: #222;
    font-weight: 500;   
}
.para{
    font-size: 18px;
    color: #222;
    line-height: 1.2em;
}
.background-bg{
    background-color: #FFFAEE;
    min-height: 500px;
    border-radius: 15px;
}
.pic{
    height:60px;
    width: 60px;
}
.pict{
    height:20px;
    width:20px;
}

.boxes{
    padding: 16px 45px 0px 45px;
}
.box-border{
    text-align: center;
    line-height: 1.2em;
}

.parav{
    padding: 0px 10px 0px 10px;
}
.padnn{
    padding:5px 10px 0px 10px;
}

@media (min-width: 1281px) {
  
    .parav{
        padding: 0px 70px 0px 70px !important;
    }

    .para-text p {
        padding: 0px 100px 0px 100px !important;
    }
    .padnn{
        padding: 15px 50px 0px 50px;
    }
    
  }
  .hdn{
      font-size: 18px;
      color:rgb(140 107 20);
  }

  
@media (min-width: 320px) and (max-width: 480px) {
  
    .hdn{
        text-align: center;
    }
    .parav{
        text-align: center;
    }
    .padnn{
        padding: 0px 20px 20px 20px;
    }

    
  }