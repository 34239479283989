.Desktop_view{
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
    background: #e8f4f0;
}

/* nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
    background: #e8f4f0;
} */

.link {
    text-decoration: none;
    display: flex;
    color: #000;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.mobile-menu-icon {
    display: none;
}
.mobile-menu-icon svg{
    font-size: 24px;
}

.icons {
    display: flex;
    align-items: center;
}

.icon-tabler {
    margin-right: 5px;
}

.github-icon {
    color: white;
}

.menu-items {
    display: flex;
    align-items: center;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    color: #000!important;
    font-weight: 500;
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .menu-items, .signup-button, .github-corner {
        display: none;
    }
    .mobile-menu-icon {
        display: block;
        color: white;
        display: flex;
        align-items: center;
        font-size: 4vh;
        cursor: pointer;
        top: 0;
        right: 0;
    }
}

.github-corner {
    position: fixed;
    top: 0;
    z-index: 99999;
    right: 0px;
}