* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Hind Madurai', sans-serif;
  color: #222;
  font-size: 16px;
}

p, p {
  font-size: 16px;
}

.nav-bg {
  color: #000;
  background-color: #e8f4f0 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #000 !important;
  font-weight: 500;
  text-decoration: none;
}

.MuiButton-containedSecondary {
  color: #fff;
  background-color: #248257 !important;
}

.Widht_nav {
  width: 50%;
  padding-left: 20px;
  background: rgb(232, 244, 240) !important;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 4px;
}

p .p {
  font-size: 16px;
}

.p-120 {
  padding-top: 120px;
}

.pb-40 {
  padding-bottom: 40px;
}

.MuiGrid-spacing-xs-3 {
  width: calc(100%) !important;
  margin: 0px !important;
}

.footer-bacground {
  background-image: url(./assets/images/footer-bg.png);
  height: auto;
}

.section-padding {
  padding: 80px 0 80px 0px;
}

.whitelogo {
  width: 154px !important;
}

.footer-text {
  padding: 25px 125px 25px 125px;
}

.color-white {
  color: #999999;
}

ul {
  list-style: none;
  padding: 0;
}

.service {
  padding: 0px;
}

.service li {
  padding: 7px 0px;
  padding-left: 0;
  color: #999999;
}

.service a {
  color: #999999;
}

.text-justify {
  text-align: justify;
}

/*----- button 3 -----*/

.btn-3::after {
  left: 0;
  bottom: 0;
  transition-delay: 0.6s;
}

.btn-3 span::after {
  transition-delay: 0.4s;
  right: 0;
  bottom: 0
}

.btn-3::before {
  right: 0;
  top: 0;
  transition-delay: 0.2s;
}

.btn-3 span::before {
  transition-delay: 0s;
  left: 0;
  top: 0;
}

.btn-3:hover::after {
  transition-delay: 0s;
}

.btn-3:hover span::after {
  transition-delay: 0.2s;
}

.btn-3:hover::before {
  transition-delay: 0.4s;
}

.btn-3:hover span::before {
  transition-delay: 0.6s;
}

/* media mobile view */

@media only screen and (max-width: 580px) {
  .footer-text {
    padding: 25px 12px 25px 12px;
    text-align: justify;
  }
  .section-padding {
    padding: 40px 15px;
  }
  .p {
    text-align: justify !important;
  }
  .footer-bacground {
    background-image: url(./assets/images/footer-bg.png);
    height: auto;
    background-size: cover;
  }
  .homebanner {
    height: 645px;
    padding-top: 20px;
  }
  .mobile-menu-icon path {
    fill: #222;
  }
  .is-display-none {
    display: none;
  }
  .col {
    padding: 20px 0px;
  }
  .cloud {
    text-align: center;
    color: #222;
    padding: 5px 0px;
    line-height: 48px;
    font-weight: bold;
    font-size: 34px;
  }
  .text_40 {
    font-size: 35px;
    line-height: 45px;
    padding: 14px;
  }
  .mob_backgroung {
    background: #e8f4f0;
    padding: 15px;
    margin-top: 18px !important;
    border-radius: 7px;
  }
  .sec3color {
    background-color: rgb(239, 231, 240);
    padding: 0px 0px 30px 0px;
  }
}

/* close */

/* media desktop view */

@media (min-width: 1281px) {
  .dropdown-menu {
    min-width: 67rem !important;
    padding: 0rem 0 !important;
  }
  .home-text {
    font-size: 36px !important;
    color: #222;
    font-weight: bold;
  }
  .cloud span {
    color: #248257;
    font-size: 37px !important;
  }
  .cloud {
    font-size: 40px !important;
    text-align: center;
    color: #222;
    padding: 5px 323px !important;
    line-height: 48px;
    font-weight: bold;
    line-height: 1.4 !important;
  }
  .cloud-pd {
    padding: 10px 138px !important;
    font-size: 18px;
    line-height: 1.5;
  }
  .arrow::after {
    content: '';
    position: absolute;
    background-image: url(./assets/images/Vector.svg);
    min-width: 188px;
    height: 10px;
    background-repeat: no-repeat;
    margin-top: 45px;
    margin-left: 35px;
  }
  .font-20 {
    font-size: 20px;
    font-weight: 400;
    padding: 20px 375px !important;
    line-height: 25px;
  }
  .get p {
    /* padding: 0px 46px !important; */
    font-size: 18px;
    line-height: 1.8;
  }
  .bg-theme p {
    padding: 0px 46px !important;
  }
  .arrow_text p {
    padding: 0px 36px !important;
    font-size: 18px;
    line-height: 1.8;
  }
  .smibold_text {
    font-size: 24px;
    font-weight: 500;
    padding: 20px 11px !important;
    line-height: 1.4;
  }
  .smibold {
    font-size: 24px;
    font-weight: 500;
    padding: 20px 44px !important;
    line-height: 1.4;
  }
  .cloud_26 {
    font-size: 28px;
    text-align: center;
    padding: 0px 387px !important;
    line-height: 1.3;
    font-weight: 600;
  }
  .span-color {
    color: #248257;
    font-size: 40px !important;
  }
  .servicetex {
    line-height: 1.4;
    padding: 0px 178px 0px 0px;
    font-size: 34px;
  }
  .pl-215 {
    padding-left: 100px !important;
  }
}

.servicetex {
  line-height: 1.4;
  padding: 10px;
  font-size: 34px;
}

.span-colorr {
  color: #248257;
  font-size: 26px;
}

.f-18 {
  font-size: 18px;
}

/* close */

.get p {
  padding: 0px 10px;
}

.homebanner img {
  max-width: 100%;
}

.home-text {
  color: #222;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.4;
}

.span-color {
  color: #248257;
  font-size: 27px;
}

.one-line {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

#text h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
  padding: 50px 0px;
  text-align: center;
}

.homebanner {
  background-image: url(./assets/images/banner-bg1.jpg);
  height: 735px;
}

.hone-p {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #222;
}

.home-ptp {
  padding: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #248257 !important;
}

.cloud {
  text-align: center;
  color: #222;
  padding: 5px 5px;
  line-height: 48px;
  font-weight: bold;
  font-size: 27px;
}

.cloud span {
  color: #248257;
  font-size: 27px;
}

/* .h1, h1 {
  font-size: 24px !important;
  line-height: 31px !important;
} */

.footer-bacground h2 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.get h1 {
  font-size: 18px;
  color: #248257;
  padding: 25px 0;
}

.bg-theme {
  background-color: #e8f4f0;
  min-height: 400px;
  border-radius: 15px;
}

.bg-themeyello {
  background-color: #FFFAEE;
  min-height: 400px;
  border-radius: 15px;
}

.image-marg {
  margin-top: -57px;
  max-width: 100%;
}

.smibold {
  font-size: 24px;
  font-weight: 500;
  padding: 20px 16px;
  line-height: 1.4;
}

.my-button {
  background: transparent;
  color: #248257;
}

.theme-color {
  color: #137765;
}

.MuiSvgIcon-root {
  fill: #0908089e !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.cloud_26 {
  font-size: 28px;
  text-align: center;
  padding: 0px 17px;
  line-height: 1.3;
  font-weight: 600;
}

.p-0 li {
  padding: 7px 0px 7px 0px !important;
}

.width-20 {
  width: 20px;
}

.width {
  max-width: 100%;
}

.max-width {
  max-width: 100%;
}

.width_Full {
  width: 100%;
}

.style-none b {
  font-size: 18px;
}

b .b {
  font-size: 18px;
}

.bg-themecolor {
  background-color: #e8f4f0;
  min-height: 400px;
}

.text_40 {
  font-size: 35px;
}

.font-20 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  padding: 18px 10px;
}

.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  color: #fff !important;
}

.MuiButton-endIcon {
  fill: #fff !important;
}

.allhome {
  padding: 5px 151px;
  line-height: 1.9;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #222;
}

p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.bg-themebg {
  background-color: #e8f4f0;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%) !important;
}

:focus {
  outline: none !important;
}

.height {
  height: 200px;
}

.bg-contact {
  background-image: url(./assets/images/bg.svg);
  background-size: cover;
  height: 510px;
  border-radius: 10px;
}

.btn-primary {
  color: #222;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}

.btn-primary {
  color: #000 !important;
  background-color: #e8f4f0 !important;
  border-color: #e8f4f0 !important;
}

.btn-primary:hover {
  color: #000 !important;
  background-color: #e8f4f0 !important;
  border-color: #e8f4f0 !important;
}

.MuiButton-label {
  text-decoration: none !important;
}

/* .pl-20 {
  padding: 0px 15px;
} */

h1 {
  font-size: 27px !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 0.5rem;
  }
}

.them-bg {
  background: #248257;
}

.f-17 {
  font-size: 17px;
}

.f-21 {
  font-size: 21px !important;
  font-weight: 500;
}

.bg-lightto {
  background: rgb(8, 170, 141);
  background: linear-gradient( 90deg, rgb(8 170 141 / 0%) 0%, rgba(8, 170, 141, 1) 88%);
}

.p-20 {
  /* padding: 40px 15px 40px 15px; */
  padding: 17px 15px !important;
  text-decoration: none !important;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 23px !important;
  /* margin-top: 1.125rem !important; */
}

.pl-3 {
  padding-left: 20px;
}

hr:not([size]) {
  height: 1px;
  margin: 10px 20px 6px 15px;
}

.MuiFab-secondary {
  color: #fff;
  background-color: #2a2a34;
  position: fixed;
  bottom: 24px;
  right: 21px;
}

.dip {
  margin: 0px 0px 21px 0px;
  padding: 4px 15px;
  max-width: 300px !important;
}

.voipfont {
  font-size: 24px;
  padding: 11px;
  font-weight: 700;
  color: #fff;
}

.ml-3 {
  margin-left: 15px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .navbar-collapse {
    background: rgb(255, 255, 255);
    padding: 20px 5px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .dropdown {
    padding-left: 15px;
  }
  #buttons{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .navbar-collapse {
    background: rgb(255, 255, 255);
    padding: 20px 5px;
    border-radius: 4px;
    margin-top: 20px;
  }
  #buttons{
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 1023px) {}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .navbar-collapse {
    background: rgb(255, 255, 255);
    padding: 20px 5px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .is-display-none {
    display: none !important;
  }
}

.MuiFab-sizeSmall {
  width: 40px;
  height: 40px;
  display: none;
}

.bold {
  font-size: 24px;
}

.d-none {
  display: none !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;
  /* position: fixed !important; */
}

@media (min-width: 576px) and (max-width: 1023px) {
  #buttons{
    display: none;
  }
  .is-display-none {
    display: none !important;
  }
  .navbar-collapse {
    background: rgb(255, 255, 255);
    padding: 20px 5px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .pl-20 {
    padding-left: 15px;
  }
  .footer-text {
    padding: 25px 25px 25px 25px;
  }
  .footer-bacground {
    /* background-image: url(/demo/static/media/footer-bg.b998cbca.png); */
    height: auto;
    background: #171614;
  }
  .dropdown-menu .col-md-4 {
    width: 100% !important;
  }
}

.navbar-light .navbar-toggler {
  color: rgb(0 0 0 / 0%) !important;
  border-color: rgba(0, 0, 0, .1);
}

.ani span {
  color: red;
}

.pro-sidebar>.pro-sidebar-inner {
  background: #FFF;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: #ffffff;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background-color: #FFF;
}

.pro-sidebar .pro-menu a:before {
  background-color: #00000000;
}

.pro-sidebar .pro-menu {
  padding-top: 5px;
  padding-bottom: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  background-color: #ccc;
}

.dropdown-menu {
  margin-top: 10px !important;
}

/* nav  */

@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown-menu.show {
    display: block;
    width: 100vw !important;
    margin-top: 11px;
    overflow-y: scroll !important;
    height: 100vh !important;
  }
  #buttons{
    display: none;
  }
}
.svgwhite{
  fill: #fff !important;
}
/* close anv */

#buttons{
  font-size: 0.875rem;
  min-width: max-content !important;
}